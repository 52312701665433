import { Component, Input, OnInit } from '@angular/core';

export interface SkillItem {
  image?: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.css']
})
export class SkillComponent implements OnInit {

  @Input() skills: SkillItem[];

  constructor() { }

  ngOnInit() {
  }

}
