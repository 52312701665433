import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-entry',
  templateUrl: './resume-entry.component.html',
  styleUrls: ['./resume-entry.component.css']
})
export class ResumeEntryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
