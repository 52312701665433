import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { SkillComponent } from "./components/skill/skill.component";
import { SkillOverviewComponent } from "./components/skill-overview/skill-overview.component";
import { PortfolioComponent } from "./components/portfolio/portfolio.component";
import { PortfolioOverviewComponent } from "./components/portfolio-overview/portfolio-overview.component";
import { AboutmeComponent } from "./components/aboutme/aboutme.component";
import { ContactComponent } from "./components/contact/contact.component";
import { ResumeOverviewComponent } from "./components/resume-overview/resume-overview.component";
import { ResumeEntryComponent } from "./components/resume-entry/resume-entry.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ImprintComponent } from "./components/imprint/imprint.component";
import { HttpService } from "./services/http.service";
import { ContentComponent } from "./content/content/content.component";
import { HomeComponent } from "./content/home/home.component";
import { AppRoutingModule } from "./app-routing.module";
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SkillComponent,
    SkillOverviewComponent,
    PortfolioComponent,
    PortfolioOverviewComponent,
    AboutmeComponent,
    ContactComponent,
    ResumeOverviewComponent,
    ResumeEntryComponent,
    FooterComponent,
    HeaderComponent,
    ImprintComponent,
    ContentComponent,
    HomeComponent,
    PrivacyPolicyComponent,
  ],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule],
  providers: [HttpService],
  bootstrap: [AppComponent],
})
export class AppModule {}
