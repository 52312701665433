import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ImprintComponent } from "./components/imprint/imprint.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { ContentComponent } from "./content/content/content.component";

const routes: any[] = [
  { path: "", component: ContentComponent },
  { path: "imprint", component: ImprintComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
