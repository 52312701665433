import { Component, OnInit } from '@angular/core';

export interface NavigationItem {
  name: string,
  href: string;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  public title: string = "Manuel Richter";
  public navigation: NavigationItem[] = [
    {
      name: "Skills",
      href: "#skills"
    },
    {
      name: "Portfolio",
      href: "#portfolio"
    },
    {
      name: "Resume",
      href: "#resume"
    },
    {
      name: "About",
      href: "#about"
    },
    {
      name: "Contact",
      href: "#contact"
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
