import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-overview',
  templateUrl: './resume-overview.component.html',
  styleUrls: ['./resume-overview.component.css']
})
export class ResumeOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
