import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "portfolio";

  constructor(private router: Router) {
    window.addEventListener("DOMContentLoaded", (event) => {
      // Navbar shrink function
      var navbarShrink = function () {
        const navbarCollapsible = document.body.querySelector("#mainNav");
        const hasHeader = document.body.querySelector("#page-header");
        if (!navbarCollapsible) {
          return;
        }
        if (window.scrollY === 0) {
          navbarCollapsible.classList.remove("navbar-shrink");
        } else {
          navbarCollapsible.classList.add("navbar-shrink");
        }

        if (!hasHeader) {
          navbarCollapsible.classList.add("navbar-shrink");
        }
      };

      // Shrink the navbar
      navbarShrink();

      // Shrink the navbar when page is scrolled
      document.addEventListener("scroll", navbarShrink);

      // Collapse responsive navbar when toggler is visible
      const navbarToggler = document.body.querySelector(".navbar-toggler");
      const responsiveNavItems = [].slice.call(
        document.querySelectorAll("#navbarResponsive .nav-link")
      );
      responsiveNavItems.map(function (responsiveNavItem) {
        responsiveNavItem.addEventListener("click", () => {
          if (window.getComputedStyle(navbarToggler).display !== "none") {
            navbarToggler["click"]();
          }
        });
      });
    });
  }
}
