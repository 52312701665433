import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { share, catchError } from "rxjs/operators";

@Injectable()
export class HttpService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.setHeaders();
  }

  private setHeaders() {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accpet-Language": "en", //TODO get language
    });
  }

  private getHeaders(): HttpHeaders {
    return this.headers;
  }

  private createHttpParams(params: object): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    for (const param in params) {
      const value = params[param];
      httpParams = httpParams.append(param, value);
    }
    return httpParams;
  }

  private handleError(error: HttpErrorResponse) {
    //this.toastService.error('error', error.message ? error.message : '');
    return throwError(error);
  }

  public request(method: string, url: string, params?: object, body?: object) {
    return this.http
      .request(method, url, {
        headers: this.getHeaders(),
        responseType: "json",
        body: body,
        withCredentials: true,
        params: this.createHttpParams(params),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error)),
        share()
      );
  }

  public get(url: string, params?: object): Observable<any> {
    return this.request("GET", url, params);
  }

  public post(url: string, body: object, params?: object): Observable<any> {
    return this.request("POST", url, params, body);
  }

  public patch(url: string, body?: object, params?: object): Observable<any> {
    return this.request("PATCH", url, params, body);
  }

  public put(url: string, body: object, params?: object): Observable<any> {
    return this.request("PUT", url, params, body);
  }

  public delete(url: string, params?: object): Observable<any> {
    return this.http.request("DELETE", url, params);
  }

  public options(url: string, params?: object): Observable<any> {
    return this.http.options(url);
    //return this.http.request("OPTIONS", url, params);
  }
}
